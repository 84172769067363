// Global Variables
var nameRegEx = "^[a-zA-Z ,.'-]+$";	// RegEx to match a name (eg. Sam Smith is valid, Sam Sm1th is not valid)
var emailRegEx = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"; // RegEx to match an email address (eg test@example.com is valid)

// Function to change the footer social media images
function socialHover(platform, status) {
	// platform is the social media site (eg. Facebook)
	// status is if the users mouse is over the image or not
	// (eg. 'in' means their mouse is over the image, 'out' means their mouse is not over the image)

	var img = document.getElementById(platform + "_small");				// Create a variable, then use the DOM to store details about the image
	if (status == "in") {												// If the mouse is over the image show the color version of the logo
		img.src = "img/social/" + platform + "_small_hover.png";
	} else {															// Else the mouse is not over the image and show the white version of the logo
		img.src = "img/social/" + platform + "_small.png";
	}
}

// Function to load the image to the overlay and then show the overlay
function overlayShow(url) {
	// url is the url of the image to show

	currentURL = document.getElementById("overlayImg").src;		// Create a variable and get the image currently set in the overlay
	if (url != currentURL) {									// If the current image url is not the same as the url passed in
		document.getElementById("overlayImg").src = url;		// Set the overlay to display the new image
	}
	overlayShowImg();											// Call the function to display the overlay
}

// Function to show the overlay and the image
function overlayShowImg(){
	document.getElementsByTagName("body")[0].style.overflow = "hidden";		// Way to disable page scroll while overlay is on show
	document.getElementById("overlay").className = "overlayShow";			// Set the class 'overlayShow' to the element with the id 'overlay'
	document.getElementById("overlayImg").className = "overlayImgShow";		// Set the class 'overlayImgShow' to the element with the id 'overlayImg'
}

// Function to hide the overlay and the image
function overlayHide(){
    document.getElementById("overlay").className = "overlayHidden";			// Set the class 'overlayHidden' to the element with the id 'overlay'
    document.getElementById("overlayImg").className = "overlayImgHidden";	// Set the class 'overlayImgHidden' to the element with the id 'overlayImg'
    document.getElementsByTagName("body")[0].style.overflow = "auto";		// Way to enable page scroll after overlay has been hidden
}